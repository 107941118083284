



:root{
  
  /* --articleBackground: #3c356c;
  --articleOutline: #756b95;
  --articleImageOutline:  #756b95;

  --articleDescriptionTextColor: rgba(255, 255, 255, 0.656);
  
  --articleButtonTextColor: #000;
  --articleButtonTextTransitionColor: #FFF;
  --articleButtonOutline: #000;
  --articleButtonBackground: #756b95;

  --articlePlusIconOutline: rgb(255, 255, 255); */

   }




.articlediv{

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
    grid-gap: 24px;

    justify-content: center;
    align-items: center;
    /* width: 70vw; */
    width: 1200px;


}
  
/* @media (max-width: 1000px) and (min-width: calc(1000px * 0.4)) {
    .articlediv {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        background-color: red;
    }
} */


.article{

    display: flex;
    position: relative;

    height: 20vh;

    /* background: var(--articleBackground); */
    /* outline: var(--articleOutline) solid 1px; */


   
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    border-radius: 12px;

}






.articleContents {
    position: absolute;
    right: 0;
    top: 0;
    width: 53%;
    height: 100%;
    padding: 12px;

    border-radius: 12px;
    background: var(--articleBackground);
    outline: var(--articleOutline) solid 1px;

  }








.articleImgDiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;

    justify-content: center;
    overflow: hidden;
    /* outline: var(--articleImageOutline) solid 1px; */

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

.articleImage {
    height: 100%;
    object-fit: contain;
}






.articleTypefaceLogo{

    display: flex;
    position: relative;
    height: 5vh;
}

.articleDescription{

    margin-top: 5px;

    color: var(--articleDescriptionTextColor);
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 18px;

}


.articleButtonDiv{

    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    bottom: 0px;
    width: calc(100% - 24px);

    padding-bottom: 12px;


}


.articleButton{

    width: 50%;
    height: 30px;
    font-family: phonk;
    font-size: 12px;

    background: var(--articleButtonBackground);
    color: var(--articleButtonTextColor);


    outline: var(--articleButtonOutline) solid 2px;


    transition: .5s ease;
    border: none;

}

.articleButton:hover {
    width: 75%;
    color: var(--articleButtonTextTransitionColor);
  }


.PlusIcon{
    height: 33px;
    margin-left: 5px;
    filter: invert(100%);
    border: var(--articlePlusIconOutline) 2px solid;
}
