body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*{
  margin: 0px;
  padding: 0px;

}




@font-face {
  font-family: "Phonk";
  src: url(/src/fonts/phonkfontreg.otf);
  }

:root{

  /* transition: background 1s ease, color 1s ease; */

  /* Header */
  /* --backgroundHeader: linear-gradient(90deg,#fe3434,#ff7728); */
  /* --backgroundHeader: linear-gradient(90deg,#eb5d47,#a933b2); */

  /* --backgroundHeader: #2d2755; */


  /* --HeaderOutline: #a4f9ff; */

  /* Rocketship Logo & typeface */
  /* --RocketshipRocket: white; */
  /* --RocketshipTypeface:  white; */

  /* top nav buttons */
  /* --navbtn: white; */
  /* --navbtnOutline: white; */
  /* --navbtnborderbottom: white; */
  /* --navbtnbackground: black; */

  /* Search Bar */
  /* --searchbarBackground: black; */
  /* --searchInputFontColor: white; */
  /* --searchInputBackground: black; */
  /* --searchIcon: white; */

  /* Menu */
  /* --menuIcon: white; */

  /* Main body */
  /* --mainbodyBackground : #170f34; */


 }






#Header {

  display: inline-flex;
  position: -webkit-sticky;
  position: sticky;

  width: 100%;
  height: 50px;

  justify-content: center;

  background: var(--backgroundHeader);
  outline: 3px solid var(--HeaderOutline);


}

#headerdiv {

  display: flex;
  width: 97.5%;

}




#logodiv{

  display: flex;
  font-size: 23px;

  height: inherit;
  margin-right: auto;

}

.RocketshipTypeface {


  font-family: Phonk, Verdana, sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;

  color: var(--RocketshipTypeface) ;

}


#rocket {

  font-size: 50px;

  display: flex;
  position: relative;

  align-self: center;
  padding-inline: 6px;

  color: var(--RocketshipRocket);

}






.categorySearchDroplist{

  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important;

  align-self: center !important;
  justify-content: center !important;


  font-size: 1.25rem !important;
  font-weight: 700 !important;
  font-family: phonk !important;


  width: -webkit-fit-content !important;


  width: -moz-fit-content !important;


  width: fit-content !important;

  background: var(--navbtnbackground) !important;
  color: var(--navbtn) !important;


  outline: 2px solid var(--navbtnOutline) !important;

  z-index: 2 !important;

    height: 30px !important;

}

.categorySearchDroplist > div {

  padding: 0px !important;
  /* height: 30px !important; */
  
position: 0px !important;

  background: var(--navbtnbackground) !important;
  z-index: 2 !important;

}

.categorySearchDroplist > input {

height: 30px !important;
padding: 0px !important;


}

.categorySearchDroplist > div > div {

  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: white !important;
  z-index: 2 !important;
}

.dropdown.icon {
  /* display: none; */

  height: 30px !important;


}


.searchbtns {

  display: flex;
  position: relative;


  justify-content: right;
  align-items: center;
  margin-left: auto;

  /* width: 100vw; */
  height: 50px;
  /* margin-left: auto;
  margin-right: auto; */



}


.searchbtns > div {

  padding-inline: 15px;

}



.currentSearchBtn {

  display: block;
  position: relative;
  
  padding: 5px;
  /* margin-inline: 15px; */

  font-size: 1.25rem;
  font-weight: 700;
  font-family: phonk;

  background: var(--navbtnbackground);
  color: var(--navbtn);
  border: 2px solid var(--navbtnOutline);
  border-bottom: 1px solid var(--navbtnborderbottom);

  border-radius: 3px;

  list-style: none;
  text-align: left;


}

.searchbutton{

  list-style: none;
  position:absolute;

  top: 50px;

  border: 2px solid var(--navbtnOutline);
  border-top-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

}

.showButton{

  display: block;
  position: relative;

  font-size: 1.25rem;
  font-weight: 700;
  font-family: phonk;

  padding-inline: 5px;

  background: var(--navbtnbackground);
  color: var(--navbtn);
  border-bottom: 1px solid var(--navbtnborderbottom);

  list-style: none;
  text-align: left;
  
}


.unselectedSearchButton{

  display: none;
}



/* .searchbutton{

position: relative;

padding: 5px;
margin-inline: 15px;

font-size: 1.25rem;
font-weight: 700;
font-family: phonk;

background: var(--navbtnbackground);
color: var(--navbtn);
outline: 2px solid var(--navbtnOutline);
border-bottom: 1px solid var(--navbtnborderbottom);

border-radius: 3px;

list-style: none;
text-align: left;

} */





#searchbar{

  width: -webkit-fit-content;

  width: -moz-fit-content;

  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding: 2.5px;
  margin-inline: 15px;


  font-family: Phonk;

  background: var(--searchbarBackground);

  border-radius: 3px;

  display: flex;
  position: relative;
  
  width:fit-content;
  height: fit-content;

  padding-inline: 5px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  margin-inline: 15px;

  font-size: 1.25rem;
  font-weight: 700;
  font-family: phonk;

  background: var(--navbtnbackground);
  color: var(--navbtn);
  outline: 2px solid var(--navbtnOutline);
  border-bottom: 1px solid var(--navbtnborderbottom);

  border-radius: 3px;

  align-content: center;
  justify-content: center;

}

#searchinput{

  font-family: phonk;
  width: 175px;
  font-size: 1.25rem;
  padding-inline: 2.5px;
  outline: transparent;

  color: var(--searchInputFontColor);
  background: var(--searchInputBackground);

  border: transparent;


}



#searchicon{

  color: var(--searchIcon) ;
  font-size: 30px;
  font-weight: 700;

}


#walletdiv{

  display: flex;
  position: relative;

  height: inherit;

}

#menuicon{

  font-size: 50px;

  line-height: 50px;

  display: flex;
  position: relative;

  align-self: center;
  padding-inline: 6px;

  color: var(--menuIcon);

}



#mainbody {

  background: var(--mainbodyBackground);
  height: calc(100vh)

}


































































:root{
  
    /* --dashboardOutline: #756b95;
    --dashboardFontColor: white;
    --dashboardBackground: #2d2755;
    --dashSelectedNavUnderline: #756b95;
    --dashContentOutline: #756b95;
    --dashContentBackground: #3c356c;
  
    --DashboardSubtitleTextColor: #FFF;
    --DashboardEstTextColor: rgba(255, 255, 255, 0.253);

    --DashboardTagTextColor: #FFF;
    --DashboardTagBackgroundColor: #462ADF;
    --DashboardTagOutlineColor: #756b95;


    --DashboardTokenOverviewDividerColor: #756b95;
    --DashboardTokenStatsNumberColor: #FFF;
    --DashboardTokenStatsSubtitleColor: rgba(255, 255, 255, 0.753);
    --DashboardTokenTickerColor: #fff;


    --DashboardChartOutlineColor: #756b95;


    --DashboardReadMoreContentTextColor: #FFF;
    --DashboardReadMoreTextColor: #fff;
    --DashboardReadMoreBackgroundColor: #2d2755;
    --DashboardReadMoreDivTopBorderColor: #756b95; */


    --dashboardOutline: #756b95;
    --dashboardFontColor: white;
    --dashboardBackground: #2d2755;
    --dashSelectedNavUnderline: #756b95;
    --dashContentOutline: #756b95;
    --dashContentBackground: #3c356c;
  
    --DashboardSubtitleTextColor: #FFF;
    --DashboardEstTextColor: rgba(255, 255, 255, 0.253);

    --DashboardTagTextColor: #FFF;
    --DashboardTagBackgroundColor: #462ADF;
    --DashboardTagOutlineColor: #756b95;


    --DashboardTokenOverviewDividerColor: #756b95;
    --DashboardTokenStatsNumberColor: #FFF;
    --DashboardTokenStatsSubtitleColor: rgba(255, 255, 255, 0.753);
    --DashboardTokenTickerColor: #fff;


    --DashboardChartOutlineColor: #756b95;


    --DashboardReadMoreContentTextColor: #FFF;
    --DashboardReadMoreTextColor: #fff;
    --DashboardReadMoreBackgroundColor: #2d2755;
    --DashboardReadMoreDivTopBorderColor: #756b95;
      
}



#dashboarddiv {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100vw;
    /* min-height: 452px; */
    padding-top: 50px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
  }
  

.dashboard{

  display: block;
  position: relative;

  width: 1200px;
  height: 390px;
  transition: height 0.5s ease;

  background: #2d2755;

  background: var(--dashboardBackground);
  color: white;
  color: var(--dashboardFontColor);
  border: #756b95 solid 1px;
  border: var(--dashboardOutline) solid 1px;
  border-radius: 6px;
  overflow: hidden;

/* 
  background-color: rgba(6, 25, 244, 0.5);
  backdrop-filter: blur(15px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */


  }






  .expanded{
    height: 800px;
  }
  
  
  
  
  #dashheader{
  
    display: flex;
  
    width: 100%;
    height: 90px;
  
    align-items: center;
  
  }


#dashtitle {

    display: block;
    position: relative;
  
  
    padding-inline: 15px;
  
  
}
  
  .ProjectDashboardLogo{
  
    height: 70px;
    padding-left: 6px;
    
}

#dashnav{

    display: inline-flex;
  
    padding-top: 25px;
    padding-left: 25px;    
    list-style: none;
    font-family: sans-serif;
  
  
}



.selectedDashNav {

    border-bottom: 3px #756b95 solid;

    border-bottom: 3px var(--dashSelectedNavUnderline) solid;
    
  
}
  
.unselectedDashNav {
  
    opacity: .65;
}
  
  
  #dashnav > li {
  
    padding-inline: 30px;
    font-size: 1.65rem;
  
  }




#dashcontent {

  display: block;
  position: relative;
  

}
  
.dashContentDiv {

  display: flex;
  position: relative;

  /* height: calc(100% - 95px); */
  height: 100%;
  width: 100%;  

  outline: solid 1px #756b95;  

  outline: solid 1px var(--dashContentOutline);
  background: #3c356c;
  background: var(--dashContentBackground);

  /* border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; */

  margin: 0px !important;
  padding: 0px !important;

  /* overflow:visible; */

  overflow-y: scroll;

}






.overviewDash{

  display: block;
  position: relative;

  height: 295px;
  width: 45%;

  margin-top: 6px;
  padding-top: 6px;
  /* overflow-y: visible; */
  overflow: hidden;
  /* overflow-y: inherit; */
  /* overflow-y: unset; */

  margin-right: -8px;



}

.overviewDash::-webkit-scrollbar {
  width: 6px; /* Set the width of the scroll bar */
}

.overviewDash::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scroll bar thumb */
}

.overviewDash::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scroll bar track */
}




.overviewSubtitle{

    display: flex;
    position: relative;
    align-items: center;
  
    font-weight: 800;
  
    color: #FFF;
  
    color: var(--DashboardSubtitleTextColor);
  
    padding: 5px;
    padding-left: 12px;
    padding-right: 15px;
  
  
    height: 50px;
    font-size: 30px;

  
  }
  
  .overviewSub{
  
    margin: 0px;
    margin-right: 5px;  
  
  }







.overviewProjectEst{
  
    color: rgba(255, 255, 255, 0.253);
  
    color: var(--DashboardEstTextColor);
    font-size: 0.8em;
  
    padding-left: 6px;

    height: 100%;
    line-height: 47px;
    
  }
  
  
  
  
  .overviewTags{
  
    display: flex;
    position: relative;
    margin-top: 12px;
  
    padding: 5px;
    padding-left: 12px;
    padding-right: 15px;
  
  }





.overviewTag{

    display: flex;
    position: relative;
  
    justify-content: center;
    align-items: center;
  
    background-color: #462ADF;
  
    background-color: var(--DashboardTagBackgroundColor);
    color: #FFF;
    color: var(--DashboardTagTextColor);
  
    font-size: 16px;
    font-weight: 600;
  
    height: 30px;
  
    outline: 1px #756b95 solid;
  
    outline: 1px var(--DashboardTagOutlineColor) solid;
    border-radius: 5px;
    
  
    margin-right: 14px;
    padding-inline: 7px;
  
  }


  


.overviewDescription{

    display: block;
    position: relative;
    font-size: 16px;
  
    margin-top: 12px;
    padding: 5px;
    padding-left: 12px;
    padding-right: 15px;
  
  }
  



.overviewToken{

    display: block;
    position: relative;
  
    width: 55%;
  
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px;
  
}





.overviewStatsdiv{

    display: flex;
    position: relative;
}
  

.overviewStats{

    display: flex;
    flex-direction: column;
    position: relative;

    justify-content: space-between;
    align-items: center;
    width: 20%;
    height: 110%;

  }



.TokenDivider{

    margin-top: 12px;
    border: 1px solid #756b95;
    border: 1px solid var(--dashboardOutline);
    border-radius: 5px;
  
    
  }
  

  
  
  
  
  .overviewChartDiv {
  
    display: block;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
  
    margin-left: 6px;  
  
  }





.dashboardChart {

    display: flex;
    position: relative;
    width: 100%;
    /* height: 100%; */
    min-height: 245px;
  
    border-radius: 7px;
  
    overflow: hidden;
  
    outline: 2px #756b95 solid;
  
    outline: 2px var(--DashboardChartOutlineColor) solid;
  
  }






.overviewStatRow{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
   
  }
  
.overviewTicker{

  display: flex;
  position: relative;

  align-content: center;
  justify-content: center;

  font-weight: 1200;
  font-family: phonk;
  color: #fff;
  color: var(--DashboardTokenTickerColor);
  height: 25px;
  font-size: 30px;

  margin-top: auto;


}


  

.StatSubtitle{

  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: rgba(255, 255, 255, 0.753);
  color: var(--DashboardTokenStatsSubtitleColor);
  
  
  }
  
  .StatNum{
  
    font-size: 30px;
    font-weight: 900;
    margin: 0;
    color: #FFF;
    color: var(--DashboardTokenStatsNumberColor);
  
  }




.overviewDivider{
  
    border: 1px solid #756b95;
  
    border: 1px solid var(--DashboardTokenOverviewDividerColor);
    height:  290px;
  
    margin-top: 12px;
  
    border-radius: 5px;
  
  }



.readmoreDiv {
    position: relative;
    display: flex;
  
    justify-content: center;
    align-items: center;
  

  
    width: calc(100% - 6px);
    height: 50px;
    
  
    /* bottom: -1px; */
  
  
    background-color: #2d2755;
  
  
    background-color: var(--DashboardReadMoreBackgroundColor);
  
    border-top: #756b95 solid 1px;
  
    border-top: var(--DashboardReadMoreDivTopBorderColor) solid 1px;
    border-right: #756b95 solid 2px;
    border-right: var(--DashboardReadMoreDivTopBorderColor) solid 2px;
    border-bottom: #756b95 solid 2px;
    border-bottom: var(--DashboardReadMoreDivTopBorderColor) solid 2px;

    border-bottom-left-radius: 6px;
    
  
    transition: all 0.3s ease-in-out;
  }








.readmoreDiv1 {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  transition: all 0.3s ease-in-out;
  color: #fff;
  color: var(--DashboardReadMoreTextColor);
}
  





.readmore {
    margin-right: 2.5px;
    margin-bottom: 0;
  
  }
  
  .downArrowIcon {
    width: 20px;
    height: 20px;
    -webkit-filter: invert();
            filter: invert();
  }
  
  .flipped{
    rotate: 180deg;
  }

  .flopped{
    rotate: 90deg;
  }

  .flop{
    rotate: -90deg;
  }





.overviewprojectResources{


    display: flex;
    position: relative;
  
    padding: 5px;
    padding-left: 6px;
    padding-right: 15px;
    margin-top: 8px;
  
    font-size: 18px;
    font-weight: 700;
  
  
  
  }



.resourcesIcon{

    width: 30px;
    height: 30px;
  
    margin-inline: 5px;
  
    -webkit-filter: invert();
  
            filter: invert();
  
  }




.projectTextContent{

    padding: 5px;
    padding-left: 12px;
    padding-right: 15px;
    color: #FFF;
    color: var(--DashboardReadMoreContentTextColor)
  
  }
  
  .projectTextContent > h3 {
  
    margin: 0px;
    padding-top: 12px;
    padding-bottom: 12px;
  
  }
  
  .projectTextContent > p {
    text-indent: 24px;
    font-size: 14px;
  }
  
  .projectTextContent > ol{
  
    margin-left: 24px;
  }
  
  .projectTextContent > ol > li {
  
    margin-top: 6px;
  
  }




  .infoResourcesSlide{
    display: block;
    position: relative;
    width: -webkit-min-content;
    width: min-content;
    height: 280px;
    margin: 12px;
    margin-left: auto; /* Push the div to the right */

  }

  .ResourceSlideShow{

    display: flex;
    position: relative;

    width: -webkit-min-content;

    width: min-content;
    height: 100%;

    justify-content: center;
    align-items: center;

    border: #756b95 solid 2px;

    border: var(--dashboardOutline) solid 2px;
    border-radius: 6px;

    overflow: hidden;

  }

  .Displayingslide{

    display: flex;
    position: relative;

    height: 100%;
    width: 500px;

    border-right: #756b95 solid 2px;

    border-right: var(--dashboardOutline) solid 2px;



  }

  .slideFadedescription{

    display: block;
    position: absolute;

    width: 100%;
    height: 100%;

    background: linear-gradient(to bottom, transparent, #2d2755);
    pointer-events: none; /* This makes the element non-clickable */

    z-index: 2;

  }



  .ResourceSlideTitle{

    font-size: 32px;
    font-weight: 700;
    font-family: phonk;
    height: 75px;


  }

  .ResourceSlideDescription{

    position: absolute;
    bottom: 0;
    left: 0;
    

    padding: 12px;

    font-size: 16px;
    font-weight: 700;
    /* font-family: phonk; */


  }



.verticalSelctionsSlider{

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  height: 100%;
  width: 50px;


}

.resourceTile{


  display: flex;
  position: relative;


  width: 35px;
  height: 35px;

  margin-top: 5px;
  margin-bottom: 5px;


  background-color: #462ADF;

  border-radius: 3px;
  border: 1px solid #756b95;


}




  .upArrow{
    rotate: 180deg;
  }
  .downArrow{

  }
  .leftArrow{
    rotate: 90deg;
  }
  .rightArrow{
    rotate: -90deg;

  }



  .InfoReadingresources{

    display: block;
    position: relative;

    width: 100%;
    height: 280px;

    padding: 12px;


  }


  .infoprojectResources{


    display: flex;
    position: relative;
    flex-wrap: wrap;
    float: right;

    margin-left: auto; /* Push the div to the right */
    align-items: center;

    height: 50px;


  /* outline: solid red 2px; */

  
  }

  .tableandtext{
    position: relative;
    display: flex;

    width: 100%;
  }


  .tableContentsBullet{

    font-size: 18px;
    font-weight: 500;
    padding: 0px;
    margin: 0px !important;

  }

  .tableContentsBullet1{

    display: flex;
    position: relative;

    align-items: center;
    justify-content: center;

    font-size: 32px;
    font-weight: 500;
    padding: 0px;
    margin: 0px !important;
    background-color: #462ADF;
    border: 1px solid #756b95;
    border: 1px solid var(--DashboardChartOutlineColor);
    font-weight: 600;
    margin: 0px !important;

    border-radius: 3px;

  }

  .InfoTitle{
    margin: 0px !important;
    padding-inline: 6px;
  }

  .infotitleArrow{
    border-left: white solid 2px;
    height: 100%;
  }


  .infoText{
    display: block;
    position: absolute;
    padding: 0px;
    font-size: 15px;
    align-items: center; 
    justify-content: center;

    padding-right: 12px;
    padding-top: 18px;

    /* bottom: 2.5px; */
  }



  .selectedArticle{
    background-color: #462ADF;
    font-weight: 600;
    padding-inline: 5px;
    margin: 0px !important;

    border-radius: 3px;
  }



:root{


    --dashboardOutline: #756b95;





    --dashboardStatBlockBackgroundColor: #6f7390;
    --dashboardStatBlockOutlineColor: #0b3fad;

    --dashboardChartSettingsButtonOutline: #756b95;
    --dashboardChartSettingsButtonBackground: #2d2755;
    --dashboardChartSettingsSelectedButtonBackground: #462adf;



}


*{
    margin: 0px;
    padding: 0px;
  
  }


.StatsRowDiv{

    display: grid;
    position: relative;
    grid-template-columns: repeat(5, 1fr);

    width: 100%;
    margin-top: 12.5px;
    grid-gap: 10px; 

    height: 100px;

}


.StatsBlock{

    display: block;
    position: relative;

    height: 100px;
    width: 100px;

    padding: 5px;

    border-radius: 2.5px;

    background-color: #6f7390;

    background-color: var(--dashboardStatBlockBackgroundColor);
    outline: solid 1px #0b3fad;
    outline: solid 1px var(--dashboardStatBlockOutlineColor);
}

.Stats2BlockWide{

    display: block;
    position: relative;

    height: 100px;
    width: 210px;

    padding: 5px;

    border-radius: 2.5px;

    background-color: #6f7390;

    background-color: var(--dashboardStatBlockBackgroundColor);
    outline: solid 1px #0b3fad;
    outline: solid 1px var(--dashboardStatBlockOutlineColor);

}


.blockStatText{

    font-weight: 500;
    font-size: 18px;

    height: 25%;

    /* outline: solid red 1px; */
}

.blockStatNumber{

    height: 50%;

    font-size: 35px;

    /* outline: solid red 1px; */

}




.ChartOptions{

    display: flex;

    width: 100%;
    height: 40px;

    align-items: center;
    justify-content: space-between;

}


.ChartType{

    display: flex;
    position: relative;

    height: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 6px;

    border: 1px solid #756b95;

    border: 1px solid var(--dashboardChartSettingsButtonOutline);
    background-color: #2d2755;
    background-color: var(--dashboardChartSettingsButtonBackground);
    overflow: hidden;


}

.ChartTypeButton{

    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;
    text-align: center;

    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    padding: 5px;


}

.ChartTypeButton:not(:last-child) {
    -webkit-border-end: 1px solid #756b95;
            border-inline-end: 1px solid #756b95;
    -webkit-border-end: 1px solid var(--dashboardChartSettingsButtonOutline);
            border-inline-end: 1px solid var(--dashboardChartSettingsButtonOutline);
  }


.ChartTime{

    display: flex;
    position: relative;

    height: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 6px;

    border: 1px solid #756b95;

    border: 1px solid var(--dashboardChartSettingsButtonOutline);
    background-color: #2d2755;
    background-color: var(--dashboardChartSettingsButtonBackground);
    overflow: hidden;

}

.selectedChartSetting{
    background-color: #462adf;
    background-color: var(--dashboardChartSettingsSelectedButtonBackground);
}



.expandLeft{


    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end; /* Shifts the div to the right */
    text-align: center;
    height: inherit;

    padding: 12px;

    border-left: var(--DashboardReadMoreDivTopBorderColor) solid 2px;
    right: 0; /* Aligns the div to the right side */

}

.expandedRight {
    width: 100% !important;
  }

  .headliine-gradent {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(360deg,#e1c4ff,#fbf5ff);
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 0;
    font-size: 46px;

    padding-top: 6px;
}



:root {



    --ibcTopTriangle: #462ADF;



}




.IBCSubtitle{

  display: flex;
  position: relative;
  align-items: center;
  padding: 5px;
  /* padding-left: 12px; */
  margin-inline: 12px;

  height: 60px;
  font-weight: 800;
  font-size: 30px;
  color: var(--DashboardSubtitleTextColor);
  background-color: #462ADF;
  background-color: var(--ibcTopTriangle);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  }
  
  .IBCSub{
  
    margin: 0px;
    height: 42px;
    flex: 1 1; /* Expand to fill remaining space */
    display: flex;
    align-items: center;

  }


  .IBCSetting{

    display: flex;
    position: relative;
    width: 20px;
    height: 20px;
    justify-content: flex-end;
    -webkit-filter: invert();
            filter: invert();
  
  }



  .glassCover{

    display: flex;
    position: absolute;

    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    z-index: 2;

    color: white;
    font-size: 48px;
    text-align: center;
    font-weight: 800;


    background-color: rgba(255, 255, 255, 0.25); /* Transparent white background */
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px); /* Blurring effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow */

    border-radius: 6px;
    overflow: hidden;
  }


  .comingSoon{
    margin: 0px !important;
  }


.circle{
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;

  background-color: silver;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.3);

  outline: solid rgb(106, 106, 106) 1px;

}

.topLeftC {
  top: 12px;
  left: 12px;
}

.topRightC {
  top: 12px;
  right: 12px;
}

.bottomLeftC {
  bottom: 12px;
  left: 12px;
}

.bottomRightC {
  bottom: 12px;
  right: 12px;
}




  .bridgeDiv{

    display: flex;
    flex-direction: column;
    position: relative;


    background-color: #462ADF;

    height: 100%;
    width: 100%;


    

  }





.IBCFromDiv{

  display: block;
  position: relative;

  width: calc(100% - 24px);

  margin-inline: 12px;

  padding: 6px;
  border-radius: 6px;


  background-color: #2d2755;
  outline: solid #756b95 1px;


}

.IBCChainSelector{

  display: flex;
  position: relative;

  height: 50px;
  width: calc(100% - 24px);

  align-items: center;
  margin: 12px;


  background-color:  #3c356d;
  outline: solid #756b95 1px;
  border-radius: 6px;

}


.IBCChainFromDiv{

  display: flex;
  position: relative;

  width: 50%;
  align-items: center;

  text-align: center;

  -webkit-padding-end: 12px;

          padding-inline-end: 12px;


}

.fromchain{
  justify-content: flex-start;


}

.destinationchain{
  justify-content: flex-end;


}





.selectChain{

  font-size: 20px;
  font-weight: 800;
  color: white;
  line-height: 50px;


}


.IBCdownarrowicon{

  width: 35px;
  height: 35px;
  margin-inline: 3px;

  -webkit-filter: invert();

          filter: invert();

}


.IBCAsseticon{

  width: 35px;
  height: 35px;


}


.IBCAssetAmountFromDiv{

  display: flex;
  position: relative;

  

  width: calc(100% - 24px);
  margin: 12px;

  border-radius: 2px;
  outline: solid #756b95 1px;


  background-color: #3c356d;


}


.IBCAssetDiv{

  display: flex;
  position: relative;
  height: 50px;
  width: 100%;
  align-items: center;




  /* outline: solid #756b95 1px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; */

}
 
.IBCAmountDiv{

  display: flex;
  position: relative;

  height: 50px;
  width: 100%;

  align-items: center;


  /* outline: solid #756b95 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; */


}

.IBCSwitchChains{

display: flex;
position: relative;

width: 35px;
height: 35px;


justify-content: center;
align-items: center;

border-radius: 50%;


}

.SwitchChainIcon{

  width: 20px;
  height: 20px;
  -webkit-filter: invert();
          filter: invert();

}




.IBCSwitchChains:hover{

  outline: 1px solid #756b95 ;
  background-color: #2d2755;


}


.StartBridging{

  /* display: flex;
  position: relative;

  width: 100%;
  height: 50px;

  background-color: aqua; */

  display: flex;
  position: relative;

  width: calc(100% - 24px);
  height: 100%;

  /* margin-inline: 12px; */
  margin: 12px;

  padding: 6px;
  border-radius: 6px;

  justify-self: center;
  align-items: center;
  text-align: center;


  background-color: #2d2755;
  outline: solid #756b95 1px;

}
*{
    margin: 0px;
    padding: 0px;
}

.explorebody{

    display: flex;
    position: relative;
    
    justify-content: center;
    align-items: center;
    
    width: 100vw;
    margin-top: 50px;


}

.appExploreDiv{


    display: flex;
    position: relative;

    width: 70vw;



}


.rowsdiv{

    display: block;
    position: relative;
  
    width: 70vw;
    padding-inline: 24px;
    padding-top: 12px;
    padding-bottom: 12px;

    border-radius: 5px;

    background-color: gray;

}


.toptitlerow{

    display: flex;
    position: relative;

    height: 50px;

}

.rowTitle{
    display: flex;
    position: relative;

    font-size: 18px;
    height: 50px;

    width: 100%;
    margin: 0px;

    justify-content: center;
    align-items: center;

    text-align: center;
    color: rgba(0, 0, 0, 0.50);

    font-family: phonk;


}

.rowRank{

    font-family: Impact,'Arial Narrow Bold', sans-serif;

    width: 200px;

}

.rowName{

}

.rowCategory{

}

.rowPrice{

}

.rowChange{

}

.rowTvl{

}

.rowMarketCap{

}

.plusicon{

    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;

}

.plusicon > img{

    height: 25px;
    opacity: 50%;
}

.rowitem{

    display: flex;
    position: relative;

    height: 40px;


}

.rowItemTitle{

    display: flex;
    position: relative;

    font-size: 18px;


    height: 40px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0px;

    justify-content: center;
    align-items: center;

    text-align: center;
    color: rgb(0, 0, 0);

    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;

}

.rowItemRank{
    width: 200px;
}

.rowItemNamediv{
    display: flex;
    justify-content: center;
    align-items: center;


}

.rowItemName{
    margin: 0px;
}

.rowItemLogo {
    width: 35px;
}

.rowItemCategory {
}
.rowItemPrice {
}
.rowItemTvl {
}
.rowItemMarketCap {
}
.rowItemBookmarket {
}


.rowItemBookmarket{

    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;

    width: 50px;

}

.rowItemBookmarket > img {

    height: 20px;
    opacity: 50%;
}




:root{
  
  /* --articleBackground: #3c356c;
  --articleOutline: #756b95;
  --articleImageOutline:  #756b95;

  --articleDescriptionTextColor: rgba(255, 255, 255, 0.656);
  
  --articleButtonTextColor: #000;
  --articleButtonTextTransitionColor: #FFF;
  --articleButtonOutline: #000;
  --articleButtonBackground: #756b95;

  --articlePlusIconOutline: rgb(255, 255, 255); */

   }




.articlediv{

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
    grid-gap: 24px;

    justify-content: center;
    align-items: center;
    /* width: 70vw; */
    width: 1200px;


}
  
/* @media (max-width: 1000px) and (min-width: calc(1000px * 0.4)) {
    .articlediv {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        background-color: red;
    }
} */


.article{

    display: flex;
    position: relative;

    height: 20vh;

    /* background: var(--articleBackground); */
    /* outline: var(--articleOutline) solid 1px; */


   
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    border-radius: 12px;

}






.articleContents {
    position: absolute;
    right: 0;
    top: 0;
    width: 53%;
    height: 100%;
    padding: 12px;

    border-radius: 12px;
    background: var(--articleBackground);
    outline: var(--articleOutline) solid 1px;

  }








.articleImgDiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;

    justify-content: center;
    overflow: hidden;
    /* outline: var(--articleImageOutline) solid 1px; */

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

.articleImage {
    height: 100%;
    object-fit: contain;
}






.articleTypefaceLogo{

    display: flex;
    position: relative;
    height: 5vh;
}

.articleDescription{

    margin-top: 5px;

    color: var(--articleDescriptionTextColor);
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 18px;

}


.articleButtonDiv{

    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    bottom: 0px;
    width: calc(100% - 24px);

    padding-bottom: 12px;


}


.articleButton{

    width: 50%;
    height: 30px;
    font-family: phonk;
    font-size: 12px;

    background: var(--articleButtonBackground);
    color: var(--articleButtonTextColor);


    outline: var(--articleButtonOutline) solid 2px;


    transition: .5s ease;
    border: none;

}

.articleButton:hover {
    width: 75%;
    color: var(--articleButtonTextTransitionColor);
  }


.PlusIcon{
    height: 33px;
    margin-left: 5px;
    -webkit-filter: invert(100%);
            filter: invert(100%);
    border: var(--articlePlusIconOutline) 2px solid;
}





.sidebardiv{
    display: block;
    position: fixed;

    top: 33%;

}


.sidebar{

    display: flex;
    position: relative;

    flex-direction: column;

    /* justify-content: center; */
    align-items: center;

    width: 60px;

    background: var(--dashContentBackground);
    /* border: 1px solid var(--dashboardOutline); */


    border-top-right-radius: 12px; 
    border-bottom-right-radius: 12px;

    border-top: 1px solid var(--dashboardOutline);
    border-right: 1px solid var(--dashboardOutline);
    border-bottom: 1px solid var(--dashboardOutline);


}



.homeIcon{

    -webkit-filter: invert();

            filter: invert();
    width: 40px;
    height: 40px;

    margin: 5px;

}

.StrategyIcon{

    -webkit-filter: invert();

            filter: invert();
    width: 35px;
    height: 35px;

    margin: 5px;
}

.sidebarHr{

    display: flex;
    position: relative;

    width: 40px;
    border: 2px solid white;
    margin-top: 5px;
    margin-bottom: 5px;
}


.sidebarApp{

    width: 60px;
    height: 60px;
    object-fit: cover;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.276) 0%, transparent 75%);

}
*{
    margin: 0px;
    padding: 0px;
}


#filterbardiv{

    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;

    height: 50px;
    margin-top: 25px;

}

#filterbar{

    display: flex;
    position: relative;

    width: 70vw;
    height: 50px;

}


.navigation{

    display: flex;
    position: relative;


    height: 50px;
    margin-left: auto;


}

.filtering{

    display: flex;
    position: relative;

    height: 50px;

    margin-right: auto;

}


.pagenav{

    width: 150px;
    height: 50px;

    align-items: center;
    justify-content: center;


}

.pagenav > p {

    font-size: 28px;
    text-align: center;
    color: white;

    font-weight: 900;

}


.displaysetting{

    outline: solid 2px white;
    background-color: black;
    border-radius: 10px;

    display: flex;
    position: relative;

    width: 90px;
    height: 30px;



}

.displaytype {

    display: flex;
    position: relative;

    width: 30px;
    height: 30px;
    
    justify-content: center;
    align-items: center;

}

.rowicondiv, .appicondiv{
    border-right: 1px solid white;
}

.displaytypeicon{


    -webkit-filter: invert(1);


            filter: invert(1);

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

}

.rowicon,.appicon {
    height: 15px;
}

.articleicon{
    height: 30px;
}






