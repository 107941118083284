
:root{


    --dashboardOutline: #756b95;





    --dashboardStatBlockBackgroundColor: #6f7390;
    --dashboardStatBlockOutlineColor: #0b3fad;

    --dashboardChartSettingsButtonOutline: #756b95;
    --dashboardChartSettingsButtonBackground: #2d2755;
    --dashboardChartSettingsSelectedButtonBackground: #462adf;



}


*{
    margin: 0px;
    padding: 0px;
  
  }


.StatsRowDiv{

    display: grid;
    position: relative;
    grid-template-columns: repeat(5, 1fr);

    width: 100%;
    margin-top: 12.5px;
    grid-gap: 10px; 

    height: 100px;

}


.StatsBlock{

    display: block;
    position: relative;

    height: 100px;
    width: 100px;

    padding: 5px;

    border-radius: 2.5px;

    background-color: var(--dashboardStatBlockBackgroundColor);
    outline: solid 1px var(--dashboardStatBlockOutlineColor);
}

.Stats2BlockWide{

    display: block;
    position: relative;

    height: 100px;
    width: 210px;

    padding: 5px;

    border-radius: 2.5px;

    background-color: var(--dashboardStatBlockBackgroundColor);
    outline: solid 1px var(--dashboardStatBlockOutlineColor);

}


.blockStatText{

    font-weight: 500;
    font-size: 18px;

    height: 25%;

    /* outline: solid red 1px; */
}

.blockStatNumber{

    height: 50%;

    font-size: 35px;

    /* outline: solid red 1px; */

}




.ChartOptions{

    display: flex;

    width: 100%;
    height: 40px;

    align-items: center;
    justify-content: space-between;

}


.ChartType{

    display: flex;
    position: relative;

    height: 25px;
    width: fit-content;

    border-radius: 6px;

    border: 1px solid var(--dashboardChartSettingsButtonOutline);
    background-color: var(--dashboardChartSettingsButtonBackground);
    overflow: hidden;


}

.ChartTypeButton{

    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;
    text-align: center;

    height: 100%;
    width: fit-content;

    padding: 5px;


}

.ChartTypeButton:not(:last-child) {
    border-inline-end: 1px solid var(--dashboardChartSettingsButtonOutline);
  }


.ChartTime{

    display: flex;
    position: relative;

    height: 25px;
    width: fit-content;

    border-radius: 6px;

    border: 1px solid var(--dashboardChartSettingsButtonOutline);
    background-color: var(--dashboardChartSettingsButtonBackground);
    overflow: hidden;

}

.selectedChartSetting{
    background-color: var(--dashboardChartSettingsSelectedButtonBackground);
}



.expandLeft{


    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end; /* Shifts the div to the right */
    text-align: center;
    height: inherit;

    padding: 12px;

    border-left: var(--DashboardReadMoreDivTopBorderColor) solid 2px;
    right: 0; /* Aligns the div to the right side */

}

.expandedRight {
    width: 100% !important;
  }

  .headliine-gradent {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(360deg,#e1c4ff,#fbf5ff);
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 0;
    font-size: 46px;

    padding-top: 6px;
}